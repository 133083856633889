import { cva, type VariantProps } from 'class-variance-authority';
import { twMerge } from 'tailwind-merge';

const grid = cva('grid', {
  variants: {
    gap: {
      none: 'gap-0',
      xs: ['gap-0.5'],
      sm: ['gap-1'],
      md: ['gap-2'],
      lg: ['gap-4'],
      xl: ['gap-8'],
      '2xl': ['gap-10'],
      '3xl': ['gap-16'],
    },
    minItemWidth: {
      none: 'grid-cols-none',
      xs: 'grid-cols-min-w-xs',
      '2xs': 'grid-cols-min-w-2xs',
      '3xs': 'grid-cols-min-w-3xs',
      sm: 'grid-cols-min-w-sm',
      '2sm': 'grid-cols-min-w-2sm',
    },
  },
  // compoundVariants: [{ gap: 'xl', minItemWidth: 'xs', class: 'bg-black' }],
  defaultVariants: {
    gap: 'md',
    minItemWidth: 'sm',
  },
});

export interface GridProps
  extends React.HTMLAttributes<HTMLElement>,
    VariantProps<typeof grid> {}

export const Grid: React.FC<GridProps> = ({
  className,
  gap,
  minItemWidth,
  ...props
}) => (
  <div className={twMerge(grid({ gap, minItemWidth, className }))} {...props} />
);
